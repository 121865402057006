import React from 'react'
import PropTypes from 'prop-types'
import List from '../components/List'
import * as teamStyles from '../styles/team.module.scss'

const Member = ( {member, direction, ...rest} ) => {
	const {firstName, lastName, name, role, pic, shortBio, keyFacts} = member;

	const pSlug = name.replace(/\s+/g, '-').toLowerCase()
	const pId = pSlug.replace(/\./g,'')

	const roleSlug = role.toLowerCase()

	if (!name) return null

	return (
		<div className={`${teamStyles.teamMember}`} {...rest} id={pId}>
		<div className={`flex ${direction === 'vertical' ? 'flex-col items-start justify-start' : 'flex-row items-center justify-start -mx-6' }`}>
		{pic &&
		  <figure className={`${direction === 'vertical' ? '' : 'w-full md:max-w-5/12 md:flex-5/12 px-6'}`}>
		  {pic}
		  </figure>
		}

		<div className={`${pId}-card ${roleSlug}-card flex-trueauto px-6 ${direction === 'vertical' ? '' : teamStyles.memberCard } `}>
		  {role &&
			<h6
			dangerouslySetInnerHTML={{__html: role}}
			className="mt-4 -mb-3 block text-primary-default uppercase text-xxs font-bold tracking-[0.0725rem]"
			/>
		  }

		  <h2
		  className="mt-5 mb-4"
		  dangerouslySetInnerHTML={{__html: name}}
		  />
		  {(firstName && lastName) &&
			<span className="hidden">{firstName} {lastName}</span>
		  }

		  {shortBio &&
			<div 
			dangerouslySetInnerHTML={{__html: shortBio}}
			/>
		  }
		  {keyFacts &&
			<List style={{marginLeft: '0', paddingLeft: '0'}}>
			  {keyFacts.map((fact, index) => <List.ListItem key={index}>{fact}</List.ListItem>  )}
			</List>
		  }
		</div>

		</div>

		</div>
	)
}

Member.propTypes = {
	direction: PropTypes.string,
}

Member.defaultProps = {
	direction: `horizontal`,
}

export default Member
