import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const AboutData = [
	{
		firstName: `Steven`,
		lastName: `Grover`,
		name: `Steven Grover`,
		role: `Owner/Operator`,
		shortBio: `
		<p>Steve is the pack leader at the casa. He’s kind, attentive and compassionate. He’ll greet you for your meet and greet, introduce your dog to the pack, feed them, and rub their belly. He’s been caring for dogs at the casa since starting the business in 2016.</p>
		`,
		keyFacts: [
			`Trained in St. John’s Ambulance For Pets`,
			'Licensed by City of Ottawa',
			`Inspected by Ottawa Fire Services`
		],
		pic:
		<StaticImage
		src={`../images/team/stevengrover-casak9.jpg`}
		alt={`Steven Grover`}
		aspectRatio={1}
		/>,
		links: [
			{
				email: null,
				phone:  null,
			},
		],
	}
]

export default AboutData
