import * as React from "react"
import Layout from "../components/layout"
// import Seo from "../components/seo"
import Seo2 from "../components/Seo2"
import { Link } from "gatsby"
import {StaticImage} from 'gatsby-plugin-image'
import Container from '../components/Container'
import PageHeader from '../components/PageHeader'
import CoverImage from '../components/CoverImage'
import Member from '../components/Member'
import Button, {ButtonGroup} from '../components/Button'
import { MediaPreFooter } from '../components/PreFooter'
import AboutData from '../data/about-data'


const TeamPage = () => {
	return (
	  <Layout>
	    {/* <Seo title="Team" /> */}
		<CoverImage crop={true}>
				<StaticImage

					src={`../images/business/casak9-6107.jpg`}
					alt="Steven Grover of Casa Canine"
					loading={`eager`}
					/>
			</CoverImage>
	    <PageHeader
	    pageTitle="Meet the Team"
	    preheader="Ottawa's favourite dog boarding service"
	    className="-mt-10 max-w-2xl mx-auto text-center">
	      <p className="mt-5 mb-0 ">
	      Can't find the answer you're looking for? Don't hesitate to <Link to={`/contact`} className="text-primary-default hover:text-primary-darker border-b border-primary-default hover:border-primary-darker">contact us</Link>.
	      </p>
	    </PageHeader>
	    <Container size="medium">
	    	{AboutData.map((member, k) => {
				return (
					<Member
					member={member}
					key={k}
					/>
				)
			})}
	    </Container>
		{/* <PreFooter
		    className="text-center mt-20"
			header={`Caring for Ottawa’s dogs since 2016`}
		    >
		    <div className="space-x-2 lg:space-x-3 xl:space-x-4">
		    <Button to={`/about`} variation="primary">Our Practice</Button>
		    <Button to={`/faq`} variation="secondary">Frequently Asked Questions</Button>
		    </div>
		    </PreFooter> */}
			<MediaPreFooter
		    containerSize={`flush`}
			header={`Caring for Ottawa’s dogs since 2016`}
			image={`v2`}
			bgClassName={`mt-20 bg-primary-default text-white`}
		    >
		    <ButtonGroup>
				<Button to={`/about`} variation="secondary">About Us</Button>
				<Button to={`/faq`} variation="primary">Frequently Asked Questions</Button>
			</ButtonGroup>
		    </MediaPreFooter>
	  </Layout>
	)
}

export const Head = () => <Seo2 title={`Team`} />

export default TeamPage
